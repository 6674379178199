.logo {
    height: 100px;
    position: absolute;
    top: 0;
}

video {
    z-index: 9;
}

.logo > img {
    height: 100%;
}

h4 {
    font-weight: 500;
}

.heavy {
    font-weight: 500;
}

.row {
    display: flex;
    flex-direction: row;
}

.column {
    display: flex;
    flex-direction: column;
}

.flex {
    display: flex !important;
}

.side-margin {
    margin-left: 0.5em;
    margin-right: 0.5em;
}

.app {
    display: flex;
    flex-wrap: wrap;
    width: 93vw;
    padding: 1.2em;
    margin-bottom: 3pt;
}

.container {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    height: 40vh;
    min-height: 40vh;
    width: 75vw;
    margin: auto;
}

.prewrap {
    white-space: pre-wrap;
}

.center-text {
    text-align: center;
}

.header {
    margin-top: 1rem;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
}

.headerText {
    margin: 0 0 0.5rem 0;
}

.half {
    flex: 50%;
}

.videoContainer {
    display: flex;
    margin-top: 1em;
    flex-direction: row;
    align-items: center;
    justify-content: space-evenly;
    width: 100%;
    margin-bottom: 1em;
}

.buttonContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 100%;
    max-width: 1000px;
}

.helperText {
    padding-left: 1em;
    display: block;
    padding-right: 1em;
}

.videoBox {
    margin-top: 0.5rem;
    display: none;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    /*max-width: 400px;*/
    max-height: 100%;
    flex-grow: 1;
}

.videoBox video {
    max-width: 100%;
    border-radius: 12px;
}

.videoBox remoteVideo {
    max-width: 100%;
    border-radius: 12px;
}

.button {
    margin: 2rem;
    width: 9rem;
    height: 3rem;
    background: #06c2ff;
    font: inherit;
    border: 1px solid white;
    border-radius: 0.4rem;
    cursor: pointer;
    color: white;
}

.button:hover,
.button:active {
    opacity: 0.88;
}

.button:focus {
    outline: none;
}

.warning {
    color: #e10000;
}

.warn {
    color: yellow;
}

.mobile {
    display: none;
}

.desktop {
    display: flex;
}

.updateEdgeText {
    padding: 6px;
    background-color: #e3d865;
    color: gray;
    border-radius: 5px;
    text-align: center;
    margin: auto;
}

.chat-box {
    position: fixed;
    background-color: #fff;
    right: 0;
    bottom: 50pt;
    margin: auto;
    display: grid;
    height: 80vh;
    width: 450px;
    border-radius: 5px;
    -webkit-box-shadow: -3px 5px 22px -6px rgba(0, 0, 0, 0.75);
    -moz-box-shadow: -3px 5px 22px -6px rgba(0, 0, 0, 0.75);
    box-shadow: -3px 5px 22px -6px rgba(0, 0, 0, 0.342);
}

.chat-box-header {
    height: 55px;
    display: flex;
    flex-flow: column;
    justify-content: space-between;
    background-color: #06c2ff;
    color: #fff;
    font-size: 14px;
    font-weight: bold;
}

.toolbar {
    height: 37px;
    padding: 3px 10px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.chat-fab {
    bottom: 7vh;
    right: 17vh;
}

.chat-control {
    position: absolute;
    bottom: 0;
    padding: 12px 20px;
    cursor: pointer;
    font-size: 15px;
    background: white;
    width: 100%;
}

.chat-msg {
    display: flex;
    flex: 20% auto;
    column-gap: 5px;
    width: 60%;
    padding: 6px;
    margin-bottom: auto;
    border-radius: 4px;
}

.user-logo {
    display: flex;
    align-items: center;
    /* color: palegreen; */
    width: 40px;
    height: 40px;
    border-radius: 50%;
    object-fit: cover;
    margin: auto;
}

.chat-msg-content {
    min-width: 4rem;
    background: #00dbff;
    word-wrap: break-word;
    display: flex;
    font-weight: 100;
    padding: 9px;
    margin-bottom: 5px;
    border-radius: 10px;
    align-items: center;
    color: #222;
}

.second-color {
    background-color: #06c2ff;
}

.chat-msg-sender {
    float: right;
    display: flex;
    flex: auto 50%;
    padding-top: 13px;
}

.contain {
    max-width: 400px;
    margin-left: 1em;
}

.evenly {
    justify-content: space-evenly;
}

.placeholder {
    margin: 1em;
    height: 240px;
    width: 320px;
    border: 1px solid #06c2ff;
    justify-content: center;
    border-radius: 12px;
}

.listItem {
    padding-top: 0.45em;
}

.placeholderimg {
    width: 100%;
    height: 100%;
}

.pad-left {
    padding-left: 2em;
}

.bellActive {
    /* Start the shake animation and make the animation last for 0.5 seconds */
    animation: shake 0.5s;
    /* When the animation is finished, start again */
    animation-iteration-count: infinite;
}

.bell {
    margin-left: 0.5em;
    height: 15px;
    width: 15px;
}

.margin-center {
    margin: auto;
}

@media (min-width: 1100px) {
    .chat-box {
        position: fixed;
        background-color: #fff;
        right: 0;
        bottom: 50pt;
        margin: auto;
        display: flex;
        flex-direction: column;
        overflow: hidden;
        height: 80vh;
        width: 450px;
        border-radius: 5px;
        -webkit-box-shadow: -3px 5px 22px -6px rgba(0, 0, 0, 0.75);
        -moz-box-shadow: -3px 5px 22px -6px rgba(0, 0, 0, 0.75);
        box-shadow: -3px 5px 22px -6px rgba(0, 0, 0, 0.342);
    }

    .chat-box-body {
        padding: 0 1rem;
        position: relative;
        height: 100%;
        overflow-x: hidden;
    }
}

@media (max-width: 1100px) {
    .mobile-margin-top {
        margin-top: 2em;
    }

    .center-text-mobile {
        text-align: center;
    }

    .updateEdgeText {
        margin-top: 10px;
    }

    .chat-control {
        position: relative;
        bottom: 0;
        padding: 12px 20px;
        cursor: pointer;
        font-size: 15px;
        background: white;
        width: 100%;
    }

    .videoContainer {
        flex-direction: row;
        margin-top: -2em;
        margin-bottom: -1em;
    }

    .mobile {
        display: flex;
    }

    .desktop {
        display: none;
    }

    .container {
        width: 100%;
    }

    .logo {
        display: none;
    }

    .mobile-half {
        flex: 50%;
    }

    .videoBox {
        margin-top: 1rem;
    }

    .large {
        flex-grow: 3;
        flex-basis: 0;
    }

    .half {
        flex: 50%;
    }

    div.videoBox video {
        width: 90%;
        height: 90%;
    }

    #remote-media-div {
        margin-right: 0;
    }

    .buttonContainer {
        text-align: -moz-center;
    }

    .tooltipVideo .tooltiptextVideo {
        left: 45%;
    }

    body {
        font-size: 12px;
    }

    .chat-box {
        bottom: 0;
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 100%;
        box-shadow: none;
        background-color: #fff;
    }

    .chat-box-header {
        width: 100%;
        height: 55px;
        position: sticky;
        padding-left: 10px;
        padding-top: 3px;
        background-color: #06c2ff;
        color: #fff;
    }

    .chat-box-header > h2 {
        margin: auto;
    }

    .chat-box-body {
        height: 100%;
        padding: 0 1rem;
        align-items: flex-end;
        overflow-y: scroll;
    }

    .btn-toggle {
        width: 23px;
        height: 23px;
    }

    .chat-fab {
        right: -75vw;
        top: 18vh;
    }

    .chat-msg {
        flex: 20% auto;
    }

    .chat-msg-sender {
        flex: auto 20%;
        padding-top: 13px;
        min-width: 20%;
        max-width: 12rem;
    }
}

.prevent-scroll {
    position: fixed;
    overflow: hidden;
    width: 100%;
    height: 100%;
}

#placeholderAndPreview video {
    z-index: 10;
    position: relative;

}

@keyframes shake {
    0% {
        transform: translate(1px, 1px) rotate(0deg);
    }
    10% {
        transform: translate(-1px, -2px) rotate(-1deg);
    }
    20% {
        transform: translate(-3px, 0px) rotate(1deg);
    }
    30% {
        transform: translate(3px, 2px) rotate(0deg);
    }
    40% {
        transform: translate(1px, -1px) rotate(1deg);
    }
    50% {
        transform: translate(-1px, 2px) rotate(-1deg);
    }
    60% {
        transform: translate(-3px, 1px) rotate(0deg);
    }
    70% {
        transform: translate(3px, 1px) rotate(-1deg);
    }
    80% {
        transform: translate(-1px, -1px) rotate(1deg);
    }
    90% {
        transform: translate(1px, 2px) rotate(0deg);
    }
    100% {
        transform: translate(1px, -2px) rotate(-1deg);
    }

}
video {
    object-fit: fill !important;
}